
import { ICompany } from "@/store/modules/companies/types";
import { authMapState } from "@/store/modules/auth";
import {
  quoteMapState,
  quoteMapMutations,
  quoteMapActions,
  quoteMapGetters
} from "@/store/modules/quote";
import Vue from "vue";
import { IQuote } from "@/store/modules/quote/types";
import { isEqual } from "lodash";
import { maxDwellingValidator, UPPValidator } from "@/forms/utils/index";
import { formatCurrencyCompactLong } from "@/plugins/filters";
import { completedStatuses } from "@/helpers/selectOptions";

export default Vue.extend({
  name: "custom-company-overrides",
  props: {
    label: {
      type: String,
      default: ""
    },
    value: {
      type: [Number, String, Array, Boolean, Object],
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showEditing: false,
      showAdjustments: false,
      currentlyEditing: false,
      loading: false,
      propertyFieldBuildingAmountOptions: [],
      descriptionHTML: "",
      labelIcon: "",
      adjustmentsExpanded: false,
      toBeEdited: false,
      maxDwellingErrorMessage: "",
      contentsAmountErrorMessage: ""
    };
  },
  mounted() {
    if (
      this.originalQuote &&
      this.originalQuote.amountDWPCompanyOverrides &&
      this.originalQuote.amountDWPCompanyOverrides.length
    ) {
      this.showAdjustments = true;
    }
  },
  methods: {
    ...quoteMapMutations({
      editingField: "SET_EDIT_FIELD",
      updateAmountDWPCompanyOverrides: "SET_EDIT_OVERRIDE_FIELD",
      updateOtherCompanyOverrides: "SET_EDIT_OVERRIDE_FIELD_OTHERS"
    }),
    ...quoteMapActions(["getPropertyFieldBuildingAmountValidations"]),
    async editValues() {
      const { data } = await this.getPropertyFieldBuildingAmountValidations({
        quoteId: this.editing._id,
        buildingAmount: this.editing.amountDWP,
        squareFeet: this.editing.squareFeet,
        companyNumbers: this.editing.companyNumbers,
        structureType: this.editing.structureType,
        endorsements: this.editing.endorsements
      });
      if (data) {
        this.propertyFieldBuildingAmountOptions = data;
        this.currentlyEditing = true;
        this.showEditing = true;
        this.showAdjustments = false;
        this.toBeEdited = true;
      }
    },
    async clickFieldArea(value: any) {
      if (value === "description") {
        await this.checkBuildingAmount();
        this.showEditing = true;
        this.showAdjustments = false;
      }
    },
    close() {
      this.showEditing = false;
      this.showAdjustments = true;
      this.adjustmentsExpanded = true;
    },
    useSingleBuildingAmount() {
      this.showEditing = false;
      this.showAdjustments = false;
      this.descriptionHTML = "";
      this.labelIcon = "";
    },
    setBuildingAmountValue(data: any) {
      const match = this.editing?.amountDWPCompanyOverrides.find(
        (override: any) =>
          override.companyNumber === data.companyNumber &&
          data.formTypes.includes(override.formType)
      );
      let value;

      if (match && match.amount) {
        value = match.amount;
        return value;
      } else {
        value =
          this.buildingAmount > data.maximumBuildingAmount
            ? data.maximumBuildingAmount
            : this.buildingAmount < data.minimumDwellingAmount
            ? data.minimumDwellingAmount
            : this.buildingAmount;
        return value;
      }
    },
    setValues(data: any, field: string) {
      const match = this.editing?.amountDWPCompanyOverrides.find(
        (override: any) =>
          override.companyNumber === data.companyNumber &&
          data.formTypes.includes(override.formType)
      );
      let value;

      if (match && match[field]) {
        value = match[field];
        return value;
      }
      //  else {
      //   value = this.contentsAmount;
      //   return value;
      // }
    },
    setValueFromExistingOverrides(data: any, field: string) {
      const original = this.getQuoteById(this.$route.params.quoteId);
      const match = original?.amountDWPCompanyOverrides?.find(
        (override: any) =>
          override.companyNumber === data.companyNumber &&
          data.formTypes.includes(override.formType)
      );
      let value = match[field];
      return value;
    },
    getCompanyName(companyNumber: string): string {
      const matched: any = this.companiesData.find(
        (company: any) => company.companyNumber === companyNumber
      );
      if (matched) {
        return matched.companyName;
      }
      return "";
    },
    handleInput(key: keyof IQuote, $event: any) {
      this.editingField({ key: key, value: $event });
      if (key === "amountDWP") {
        this.editingField({
          key: "amountDWPCompanyOverrides",
          value: []
        });
      }
    },
    async checkBuildingAmount() {
      if (this.editing && this.editing.amountDWP) {
        this.loading = true;
        const { data } = await this.getPropertyFieldBuildingAmountValidations({
          quoteId: this.editing._id,
          buildingAmount: this.editing.amountDWP,
          squareFeet: this.editing.squareFeet,
          companyNumbers: this.editing.companyNumbers,
          structureType: this.editing.structureType,
          endorsements: this.editing.endorsements
        });
        if (data.length > 0) {
          this.propertyFieldBuildingAmountOptions = data;
        }
        this.loading = false;
      }
    },
    calculatedBuildingAmount(data: any) {
      const buildingAmnt =
        this.buildingAmount > data.maximumBuildingAmount
          ? data.maximumBuildingAmount
          : this.buildingAmount < data.minimumDwellingAmount
          ? data.minimumDwellingAmount
          : this.buildingAmount;
      return buildingAmnt;
    },
    emitValues(
      {
        formTypes,
        companyNumber
      }: { formTypes: string[]; companyNumber: number },
      index: any,
      $event: any
    ) {
      for (const formType of formTypes) {
        this.updateAmountDWPCompanyOverrides({
          formType: formType,
          companyNumber: companyNumber,
          value: $event,
          contentsAmount: this.setValues(
            { companyNumber, formTypes: [formType] },
            "contentsAmount"
          )
        });
      }
    },
    emitOtherValues(
      {
        formTypes,
        companyNumber
      }: { formTypes: string[]; companyNumber: number },
      field: any,
      $event: any
    ) {
      for (const formType of formTypes) {
        this.updateOtherCompanyOverrides({
          formType: formType,
          companyNumber: companyNumber,
          value: $event,
          field: field
        });
      }
    },
    maybeClearBuildingAmountData(newValue: any, oldValue: any) {
      if (
        this.editing &&
        this.editing.amountDWP &&
        !isEqual(newValue, oldValue)
      ) {
        this.editingField({ key: "amountDWP", value: "" });
        this.editingField({
          key: "amountDWPCompanyOverrides",
          value: []
        });
        this.showAdjustments = false;
        this.$appNotifySuccess(
          this.__getText("fields", "buildingAmountCleared")
        );
      }
    },
    maxDwellingValidator(): boolean {
      const { success, message } = maxDwellingValidator(this.editing.amountDWP);
      this.maxDwellingErrorMessage = message;
      this.descriptionHTML = "";
      return success;
    },
    contentsAmountValidator(): boolean {
      const { success, message } = UPPValidator();
      this.contentsAmountErrorMessage = message;
      this.descriptionHTML = message;
      return success;
    },
    formatCurrency(amount: any) {
      return formatCurrencyCompactLong(amount);
    }
  },
  computed: {
    ...authMapState(["initData"]),
    ...quoteMapState(["editing"]),
    ...quoteMapGetters(["getQuoteById"]),
    faIcon(): string {
      return this.adjustmentsExpanded ? "chevron-up" : "chevron-right";
    },
    companiesData(): ICompany[] {
      if (this.$getCurrentUser.role === "admin") {
        return this.initData?.companies || [];
      } else {
        return this.$getCurrentUser.companiesData;
      }
    },
    buildingAmount(): number {
      const { editing } = this;
      const { amountDWP } = editing;
      return amountDWP;
    },
    contentsAmount(): number {
      const { amountUPP = "" } = this.editing;
      return amountUPP;
    },
    originalQuote(): IQuote {
      return this.getQuoteById(this.$route.params.quoteId);
    },
    buildingAmountDisplayOptions(): any[] {
      const uniqueOptions: any[] = [];
      const { propertyFieldBuildingAmountOptions } = this;

      const hasSameBuildingAmountRequirements = (option1: any, option2: any) =>
        option1.maximumBuildingAmount === option2.maximumBuildingAmount &&
        option1.minimumDwellingAmount === option2.minimumDwellingAmount;

      const isSameCompany = (option1: any, option2: any) =>
        option1.companyNumber === option2.companyNumber;
      if (
        propertyFieldBuildingAmountOptions &&
        propertyFieldBuildingAmountOptions.length > 0
      ) {
        for (const companyData of propertyFieldBuildingAmountOptions as any[]) {
          const existingCompanyWithSameBuildingAmountRequirements = uniqueOptions.findIndex(
            (
              a // eslint-disable-line prettier/prettier
            ) =>
              isSameCompany(a, companyData) &&
              hasSameBuildingAmountRequirements(a, companyData)
          );

          if (existingCompanyWithSameBuildingAmountRequirements === -1) {
            uniqueOptions.push({
              companyName: companyData.companyName,
              companyNumber: companyData.companyNumber,
              formTypes: [companyData.formType],
              minimumDwellingAmount: companyData.minimumDwellingAmount,
              maximumBuildingAmount: companyData.maximumBuildingAmount
            });
          } else {
            uniqueOptions[
              existingCompanyWithSameBuildingAmountRequirements
            ].formTypes.push(companyData.formType);
          }
        }
      }
      for (let option of uniqueOptions) {
        option.contentsAmount = this.contentsAmount;
      }
      return uniqueOptions;
    },
    valuesToShow(): any[] {
      if (this.adjustmentsExpanded || this.value.length < 5) {
        return this.value;
      } else {
        return this.value.slice(0, 4);
      }
    },
    maximumExceeded(): boolean {
      return parseFloat(this.editing.amountDWP) > 350000;
    },
    disableInputFields(): boolean {
      return completedStatuses.includes(this.editing.status);
    }
  },
  watch: {
    "editing.squareFeet": {
      async handler(newValue, oldValue) {
        this.maybeClearBuildingAmountData(
          newValue.toString(),
          oldValue.toString()
        );
      },
      immediate: false
    },
    "editing.structureType": {
      async handler(newValue, oldValue) {
        this.maybeClearBuildingAmountData(newValue, oldValue);
      },
      immediate: false
    }
  }
});
