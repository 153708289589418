import {
  IAgency,
  AgencyActions,
  AgencyState,
  AgencyMutations,
  AgencyGetters
} from "./types";
import { DefineActions } from "vuex-type-helper";
import * as types from "@/store/mutation-types";
import {
  GenericCreateOne,
  GenericGetOne,
  GenericDeleteOne,
  GenericUpdateOne,
  GenericMakeRequest,
  GenericGetMany
} from "@/store/helpers/actions";
import AgencyService from "@/services/agency.service";
import { getAgencyDefaults } from "@/helpers/defaultObjectGenerators";
import agencyService from "@/services/agency.service";

export const actions: DefineActions<
  AgencyActions,
  AgencyState,
  AgencyMutations,
  AgencyGetters
> = {
  createAgency: GenericCreateOne<
    AgencyState,
    AgencyGetters,
    AgencyActions,
    AgencyMutations,
    IAgency
  >(AgencyService, types.SET_ITEM as any),
  getAgencies: GenericGetMany<
    AgencyState,
    AgencyGetters,
    AgencyActions,
    AgencyMutations
  >(AgencyService, "agencies", types.SET_ITEM as any),
  getSubAgencies: GenericGetMany<
    AgencyState,
    AgencyGetters,
    AgencyActions,
    AgencyMutations
  >(AgencyService, "subAgencies", types.SET_SUB_AGENCIES as any),
  getAgency: GenericGetOne<
    AgencyState,
    AgencyGetters,
    AgencyActions,
    AgencyMutations
  >(AgencyService, types.SET_ITEM as any, getAgencyDefaults),
  deleteAgency: GenericDeleteOne<
    AgencyState,
    AgencyGetters,
    AgencyActions,
    AgencyMutations
  >(AgencyService, types.DELETE_ITEM as any),
  updateAgency: GenericUpdateOne<
    AgencyState,
    AgencyGetters,
    AgencyActions,
    AgencyMutations,
    { id: string; update: Partial<IAgency> }
  >(AgencyService, types.SET_ITEM as any, getAgencyDefaults),
  verifyAgencyCode: GenericMakeRequest<
    AgencyState,
    AgencyGetters,
    AgencyActions,
    AgencyMutations
  >(AgencyService.verifyAgencyCode.bind(AgencyService)),
  assignAgentsToAgencies: async ({ commit }, { id, agentIds }) => {
    try {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: true } as any
      );
      const response = await agencyService.assignAgentsToAgencies(id, agentIds);
      const responseData = response.data;
      commit(types.SET_ITEM, response.data);
      return responseData;
    } finally {
      commit(
        types.SET_STATE as any,
        { key: "makingApiRequest", value: false } as any
      );
    }
  }
  //
};
