import { IUserModel } from "@/types";
import defaultsDeep from "lodash/defaultsDeep";
import cloneDeep from "lodash/cloneDeep";
import { omit } from "lodash";
import {
  baseAgency,
  baseAtlasFile,
  baseCommunication,
  baseCounty,
  baseImports,
  baseNotification,
  quoteBase,
  settingsBase,
  userBase,
  baseGbsHelp
} from "@/helpers/defaultObjects";
import { ICommunication } from "@/store/modules/communication/types";
import { IAtlasfile } from "@/store/modules/atlasfile/types";
import { INotification } from "@/store/modules/notification/types";
import { ICounty } from "@/store/modules/county/types";
import { IImport } from "@/store/modules/import/types";
import { ISetting } from "@/store/modules/setting/types";
import { IQuote } from "@/store/modules/quote/types";
import { IAgency } from "@/store/modules/agency/types";

export function getUserDefaults(user: Partial<IUserModel>): IUserModel {
  return defaultsDeep(cloneDeep(user), Object.assign({}, userBase()));
}

export function getCommunicationDefaults(
  communication: Partial<ICommunication>
): Partial<ICommunication> {
  return defaultsDeep(
    cloneDeep(communication),
    Object.assign({}, baseCommunication())
  );
}

export function getCommunicationDefaultsNoDeliveryMethods(
  communication: Partial<ICommunication>
): Partial<ICommunication> {
  const base = omit(baseCommunication, "alternateDeliveryMethods");
  return defaultsDeep(cloneDeep(communication), Object.assign({}, base));
}

export function getSettingsDefaults(
  settings: Partial<ISetting>
): Partial<ISetting> {
  return defaultsDeep(cloneDeep(settings), Object.assign({}, settingsBase()));
}
export function getGbsHelpDefaults(
  gbsHelp: Partial<ISetting>
): Partial<ISetting> {
  return defaultsDeep(cloneDeep(gbsHelp), Object.assign({}, baseGbsHelp()));
}

export function getAtlasfileDefaults(file: Partial<IAtlasfile>): IUserModel {
  return defaultsDeep(cloneDeep(file), Object.assign({}, baseAtlasFile()));
}

export function getQuoteDefaults(data: Partial<IAtlasfile>): IQuote {
  return defaultsDeep(cloneDeep(data), Object.assign({}, quoteBase()));
}

export function getNotificationDefaults(
  notification: Partial<INotification>
): INotification {
  return defaultsDeep(
    cloneDeep(notification),
    Object.assign({}, baseNotification())
  );
}

export function getCountyDefaults(county: Partial<ICounty>): ICounty {
  return defaultsDeep(cloneDeep(county), Object.assign({}, baseCounty()));
}

export function getImportDefaults(importData: Partial<IImport>): IImport {
  return defaultsDeep(cloneDeep(importData), Object.assign({}, baseImports()));
}

export function getAgencyDefaults(county: Partial<IAgency>): IAgency {
  return defaultsDeep(cloneDeep(county), Object.assign({}, baseAgency()));
}
