export const global = {
  saveResourceText: (resource: string) =>
    resource ? `Save ${resource}` : `Save`,
  createNewText: (resource: string) =>
    resource ? `Create New ${resource}` : `Create New`,
  deleteResourceText: (resource: string) =>
    resource ? `Delete ${resource}` : `Delete`,
  addResourceText: (resource: string) => `Add ${resource}`,
  assignResourceText: (resource: string) => `Assign ${resource}`,
  noResourceText: (resource: string) => `No ${resource}`,
  loadingResourceText: (resource: string) => `Loading ${resource}`,
  confirmResourceText: (resource: string) => `Confirm ${resource}`,
  clickResourceText: (resource: string) => `Click to ${resource}`,
  editResourceText: (resource: string) => `Edit ${resource}`,
  viewResourceText: (resource: string) => `View ${resource}`,
  unsavedChangesText: "You have unsaved changes.",
  unassignUserText:
    "Are you sure you want to unassign this user from this profile? The user will no longer have access to this profile, or any documents/investments associated with the profile.",
  unsavedChangesWarningText: "You have unsaved changes. Continue?",
  warningHeaderText: "Warning",
  loginLinkText:
    "<router-link to='/login' class='text-blue-500'>Login</router-link>",
  updatePasswordText: "Enter your new password below",
  updatePasswordButtonText: "Update Password",
  updatePasswordHeader: "Update Password",
  updatePasswordSuccess:
    "Password update successful. Click the link below to sign in.",
  adminDashboardMessage: "Hello!",
  userDashboardMessage: "Hello!",
  checkLaterText: "Check back later.",
  noResultsQueryText: "No results matched your query.",
  tryLaterText: "Please try again",
  verifyText: "Click here to verify",
  enterCodeText: "Click here to enter code",
  willSaveChangesTitle: "Save Changes",
  willSaveChanges: "This will save the changes you made. Continue?",
  resendCodeText: (minutes: number) =>
    `You can resend code in ${minutes} minutes`,
  verificationCodeSentText:
    "A verification code has been sent to your phone. Please enter it below",
  sendverificationCodeText:
    "To verify your phone number, we are going to send a code via sms to:",
  errorCreating: (resource: string) => `Error creating ${resource}`,
  errorUpdating: (resource: string) => `Error updating ${resource}`,
  emergencyMaintenanceDisabledHeader:
    "<b>Attention!!!</b> emergency mode is currently disabled. Enabling emergency mode will bar access to the site to all but admins.",
  emergencyMaintenanceEnabledHeader:
    "<b class='text-red-500 text-md'>Warning!!!</b> Emergency mode is currently <b>enabled</b>. The website is now only accessible to admins. Please endeavor to disable this when the emergency is over.",
  clickMandatoryEndorsements:
    "Click Mandatory endorsements to view endorsements already added.",
  clickToAddEndorsements:
    "Click Add Endorsements to add additional endorsements.",
  endorsementsDisclaimer:
    "* Not all endorsements apply to all companies. Please review your quote sheet after rating to verify endorsements.",
  creditsDisclaimer:
    "* Not all credits apply to all companies. Please review your quote sheet after rating to verify credits.",
  bulkUserImportMessage:
    "*CSV file must have EMAIL, FIRST_NAME, LAST_NAME, PRIMARY_PHONE,SECONDARY_PHONE, ROLE, STATUS, PASSWORD, PHYSICAL_ADDRESS_UNIT_NUMBER, PHYSICAL_ADDRESS_HOUSE_NUMBER, PHYSICAL_ADDRESS_STREET_NAME, PHYSICAL_ADDRESS_STREET_TYPE, PHYSICAL_ADDRESS_STREET_DIRECTION, PHYSICAL_ADDRESS_CITY, PHYSICAL_ADDRESS_STATE, PHYSICAL_ADDRESS_ZIP_CODE, PHYSICAL_ADDRESS_PO_BOX_FORMAT, MAILING_ADDRESS_UNIT_NUMBER, MAILING_ADDRESS_HOUSE_NUMBER, MAILING_ADDRESS_STREET_NAME, MAILING_ADDRESS_STREET_TYPE, MAILING_ADDRESS_STREET_DIRECTION, MAILING_ADDRESS_CITY, MAILING_ADDRESS_STATE, MAILING_ADDRESS_ZIP_CODE, MAILING_ADDRESS_PO_BOX_FORMAT, AGENT_AGENCY, ALL_AGENCY_CODES, COMPANY_NUMBER header",
  bulkMortgageImportMessage:
    "*CSV file must have MORTGAGE NAME, SHORT NAME, ADDRESS, CITY, STATE, ZIPCODE, AICODE",
  bulkEndorsementImportMessage:
    "*CSV file must have FORM_NUMBER, FORM_CODE, FORM_NAME, FORM_USAGE, FORM_LABEL, APPLIES_TO, FORM_TYPES, DATA, COMPANIES",
  policyLapsedEffectiveDateMessage:
    "Effective date of cancellation must be within the past 90 days and property must not be in foreclosure to use this form. \n You must attach documentation supporting the new cancellation date.\n Would you like to continue?",
  policyLapsedMessage: (policyNumber: number, actualCancellationDt: string) => {
    let wording = `Policy ${policyNumber}`;
    wording += actualCancellationDt
      ? ` lapsed on ${actualCancellationDt}`
      : "is lapsed";
    return `${wording}. Are you trying to back date this cancellation?`;
  },
  signDocument: "Agree to Subscriber Agreement",
  readDocument: "Read Subscriber Agreement"
};
