import { Route } from "vue-router";
import store from "@/store";
import { bugSnagClient } from "../../../plugins/bugsnag";
// import router from "@/router";

const redirectToLogin = (next: any, from: string) => {
  if (from === "/") {
    return next({ path: "/login" });
  } else {
    return next({ path: "/login", query: { redirectFrom: from } });
  }
};

const isRouteAuthenticationRequired = (toRoute: Route) => ({
  // eslint-disable-next-line prettier/prettier
  required: toRoute?.matched?.some(route => route.meta.requireAuth),
  allowed: toRoute?.meta?.allowedRoles
});

export const getCurrentUser = async (
  toRoute: Route,
  fromRoute: Route,
  next: any
) => {
  try {
    // If the user has a token, validate it
    const hasToken = localStorage.getItem("access");
    if (!hasToken) {
      if (store.getters["auth/isLoggedIn"]) {
        await store.dispatch("auth/logout");
      }
    } else {
      if (toRoute.path === fromRoute.path) {
        return next();
      }
      // Check to see if the user has a active token
      const authCheck = await store.dispatch("auth/authCheckGetUser");
      /* istanbul ignore next */
      if (authCheck && authCheck.authorized) {
        // if the user is authorized, then we have setup the current user
        // in the store. We can continue.
        const initUserDataSetup = store.getters["auth/initialDataSetup"];
        if (!initUserDataSetup) {
          await store.dispatch("auth/init");
        }

        const user = store.getters["auth/getCurrentUser"];
        if (user && user.email) {
          bugSnagClient.setUser(user._id, user.email);
          bugSnagClient.addMetadata("user", {
            accountType: user.role,
            userCode: user.agentCode || user.adjusterCode || "",
            agencyId: user?.agencyId,
            deleted: user?.deleted,
            isBanned: user?.isBanned,
            status: user?.status
          });
        }

        return next();
      }
    }

    throw new Error("Missing or invalid Token");
  } catch (e) {
    // if (e.status === 423) {
    //   router.push("/emergency-maintenance")
    // }
    // If the user did not authenticate, check to see if the requested route
    // requires authorization. If not, let them continue.
    const { required } = isRouteAuthenticationRequired(toRoute);
    if (!required) {
      return next();
    }
    // The requested route requires authentication, and the
    // user is not authenticated. Send them to login page
    return redirectToLogin(next, toRoute.fullPath);
  }
};
