// prettier-ignore
export const agentApplication = {
         agencyName: "Please Enter Agency Name",
         fein: "Please Enter FEIN or SS#",
         entityType: "Please Select Entity Type",
         city: "City",
         state: "State",
         postalCode: "Zip Code",
         phone: "Enter Phone Number",
         fax: "Enter Fax",
         physicalAddress: "Enter Physical Address",
         companyWebAddress: "Enter Company Website Address(If Applicable)",
         ownersFirstName: "Owners/Principal First Name",
         ownersLastName: "Owners/Principal Last Name",
         ownersName: "Owners/Principal Full Name",
         ownersEmail: "Owners/Principal Email Address",
         supervisorsEmail: "Office Manager/Supervisor Email Address",
         mainPersonalLinesContact: "Main Personal Lines Contact",
         commercialBusinessSplit:
           "What percent of your business is Commercial Lines?",
         personalBusinessSplit:
           "What percent of your business is Personal Lines?",
         totalNumberOfAgencyEmployees: "Total Number of Agency Employees",
         totalNumberOfPersonalLineStaff: "Total Number of Personal Lines Staff",
         howOurMarketFitsWithOtherMarketsAgentRepresents:
           "How would our market fit in with the other markets you represent?",
         totalCombinedYearsForPersonalLinesStaff:
           "Total Combined Years of Experience for Personal Lines Staff",
         motiveForSeekingAppointment:
           "Why are you seeking an appointment with our company?",
         factorsInfluencingPlacementOfResidentialPropertyBusiness:
           "What factors influence where you place your residential property business?",
         primaryFocusWhenSellingPropertyPolicy:
           "What is your primary focus when selling a residential property policy?",
         personalLinesPremiumVolumeInLast6Months:
           "Personal Lines Premium Volume In Last 6 Months ($)",
         anyMarketsAccessedThroughMGA: "If yes, Which one(s)",
         marketsAccessedThroughMGA: "Markets Accessed Through MGA",
         atlasWillReplaceCurrentCarrier: "Atlas Will Replace CurrentCarrier",
         firstYearPremiumCommitment: "1st Year Premium Commitment",
         agencyUsesComparativeRater:
           "Does your agency use a Comparative Rater?",
         email: "Agent Email",
         lastName: "Agent Last Name",
         firstName: "Agent First Name",
         streetName: "Street Name",
         country: "Country",
         streetDirection: "Street Direction",
         houseNumber: "House Number",
         streetType: "Street Type",
         county: "County",
         top4ResidentialPropertyCarriers: "",
         carrier1: "Carrier 1",
         carrier2: "Carrier 2",
         carrier3: "Carrier 3",
         carrier4: "Carrier 4",
         ifYes: "If yes, Which one(s)",
         familiarResidentialProductForms:
           "Which residential property product forms are you most familiar with? (e.g. HOB, TDP-3, ISO-3)",
         howWill:
           "How would our market fit in with the other markets you represent?",
         whatMarketingPlans:
           "What marketing plans do you have in place to grow your residential property business? Must check at least 3 sources.",
         perMonth: "Applications per Month",
         premium: "1st Year Premium Commitment",
         agentApplications: "Agent Applications",
         modal: "Approve",
         companyIds: "Company IDs",
         agentCode: "Agent Code",
         cancel: "Cancel",
         submit: "Submit",
         other: "other",
         underwritingChallenges:
           "What underwriting challenges does your market represent? (e. g. coastal wind, hail, unusual liability exposure)"
       };
