
import { countyMapActions, countyMapState } from "@/store/modules/county";
import Vue from "vue";

interface IData {
  loading: boolean;
}

export default Vue.extend({
  name: "search-select-gbshelps",
  props: {
    selected: {
      type: [Number, String, Array, Boolean],
      default: ""
    },
    // when used in form builder
    value: {
      type: [Number, String, Array, Boolean],
      default: ""
    },
    label: {
      type: String,
      default: "",
      required: false
    },
    placeholder: {
      type: String,
      default: "search counties",
      required: false
    },
    multiple: {
      type: Boolean,
      default: false,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    useLabelAsValue: {
      type: Boolean,
      required: false,
      default: false
    },
    gbsSearchType: {
      type: String,
      required: false,
      default: "county",
      validator(value: string) {
        return ["county", "city", "zip"].includes(value);
      }
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      default: false,
      required: false
    },
    allowCreate: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  mounted() {
    if (Array.isArray(this.selected) && this.selected.length > 0) {
      this.makeOptionsForSelectedDocuments(this.selected as any);
    }
  },
  data(): IData {
    return {
      loading: false
    };
  },

  methods: {
    ...countyMapActions(["getCounties", "getGbsCounties"]),
    handleChange(event: string) {
      this.$emit("change", event);
      const selectedOption = this.options.find(
        (data: { label: string; value: string }) => data.value === event
      );
      this.$emit("getSelectedOption", selectedOption);
    },
    async remoteMethod(searchTerm: string) {
      if (searchTerm.trim().length === 0) return;
      try {
        this.loading = true;
        await this.getGbsCounties({
          query: { __query: searchTerm }
        });
      } catch (error) {
        this.$message({
          type: "error",
          message: "Error fetching documents"
        });
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = true;
      }
    },
    async makeOptionsForSelectedDocuments(selected: string[]) {
      await this.getCounties({ query: { _id__in: selected } });
    }
  },
  computed: {
    ...countyMapState(["counties"]),
    options(): any[] {
      const uniqueCounties = new Set<string>();
      const options = [];
      for (const county of this.counties) {
        if (
          !uniqueCounties.has(county[this.searchType]) &&
          !!county[this.searchType]
        ) {
          options.push({
            label: county[this.searchType],
            value: !this.useLabelAsValue ? county._id : county[this.searchType]
          });
          uniqueCounties.add(county[this.searchType]);
        }
      }
      return options;
    },
    searchType(): string {
      switch (this.gbsSearchType) {
        case "county":
          return "county";
        case "city":
          return "city";
        case "zip":
          return "zipCode";
        default:
          return "county";
      }
    }
  }
});
