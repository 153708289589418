import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import { AgencyState, AgencyMutations, StateKeys, IAgency } from "./types";
import {
  GenericDeleteItem,
  GenericSetEdit,
  GenericSetEditField,
  GenericSetItem,
  GenericSetState,
  GenericSetStates,
  GenericClearStore
} from "@/store/helpers";
import { getAgencyDefaults } from "@/helpers/defaultObjectGenerators";
import { defaultState } from "./state";

export const mutations: DefineMutations<AgencyMutations, AgencyState> = {
  [types.SET_STATE]: GenericSetState<AgencyState, StateKeys>(),
  [types.SET_STATES]: GenericSetStates<AgencyState>(),
  [types.SET_EDIT]: GenericSetEdit<AgencyState, string | IAgency>(
    "agencies",
    getAgencyDefaults
  ),
  [types.SET_EDIT_FIELD]: GenericSetEditField<AgencyState, keyof IAgency>(),
  [types.SET_ITEM]: GenericSetItem<AgencyState, IAgency>(
    "agencies",
    getAgencyDefaults
  ),
  [types.SET_SUB_AGENCIES]: GenericSetItem<AgencyState, IAgency>(
    "subAgencies",
    getAgencyDefaults
  ),
  [types.DELETE_ITEM]: GenericDeleteItem<AgencyState>("agencies", "_id"),
  [types.CLEAR_AGENCY_STORE]: GenericClearStore<AgencyState>(defaultState())
};
