import BaseCollectionService from "./base.collection.service";

class AtlasPlatformService extends BaseCollectionService {
  constructor() {
    super("atlasPlatform");
  }
  async getAtlasPlatforms(query?: Record<string, any>) {
    const path = `atlasPlatforms`;
    return await this.request(
      {
        method: "get",
        url: path,
        params: query
      },
      { useToken: "access" }
    );
  }
  public async deleteBulkAtlasPlatforms(_ids: string[]) {
    const path = `atlasPlatforms-bulk`;
    return await this.request(
      {
        method: "delete",
        url: path,
        data: { _ids }
      },
      { useToken: "access" }
    );
  }
}

export default new AtlasPlatformService();
