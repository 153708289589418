import * as types from "@/store/mutation-types";
import { DefineMutations } from "vuex-type-helper";
import { UserState, UserMutations, UserFields } from "./types";
import { getUserDefaults } from "@/helpers/defaultObjectGenerators";

import { IUserModel } from "@/types";
import {
  GenericDeleteItem,
  GenericSetEdit,
  GenericSetEditField,
  GenericSetItem,
  GenericSetState,
  GenericClearStore
} from "@/store/helpers";

import { defaultState } from "./state";

export const mutations: DefineMutations<UserMutations, UserState> = {
  [types.SET_STATE]: GenericSetState<UserState, keyof UserState>(),
  [types.SET_USER]: GenericSetItem<UserState, IUserModel>(
    "users",
    getUserDefaults
  ),
  [types.SET_USER_EDIT_FIELD]: GenericSetEditField<UserState, UserFields>(),
  [types.SET_EDIT_USER]: GenericSetEdit<
    UserState,
    string | Partial<IUserModel>
  >("users", getUserDefaults),
  [types.DELETE_USER]: GenericDeleteItem<UserState>("users", "_id"),
  [types.SET_UNDER_WRITER]: GenericSetItem<UserState, IUserModel>(
    "underWriters",
    getUserDefaults
  ),
  [types.CLEAR_USER_STORE]: GenericClearStore<UserState>(defaultState())
};
